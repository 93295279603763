






































































































































import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'

type UserRank = {
  title: string,
  reputation: number
  xp: number
}

import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { InputSetups } from "../../mixins/input-setups";
import AddAdmin from '@/components/AddAdmin/AddAdmin.vue'
import { AddAdminType } from '@/includes/types/AddAdmin/Enums';
import Placeholders from '@/mixins/placeholders/placeholders'
import TempCurrentModuleMixin from '@/includes/logic/Modules/mixins/TempCurrentModuleMixin'
import { ModuleTypesEnum } from '@/includes/logic/Modules/types/types'
import CurrentModule from '@/components/Modules/components/CurrentModule.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue';
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    CurrentModule,
    CenteredColumnLayout,
    PageTitle,
    HighlightAnchor,
    ConfigField,
    AddAdmin
  },
  data() {
    return {
      AddAdminType
    }
  }
})
export default class ReportSystem extends Mixins(UseFields, InputSetups, Placeholders, TariffsTagsHelper, TempCurrentModuleMixin) {
  created() {
    this.setCurrentModule(ModuleTypesEnum.ReportCommandHandlerModule)
  }
}
